import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { PayrollIncomeType } from '@time-tracking/interfaces/settings.interface';
import { TranslatableKey } from '@app/types/translatable.type';

export class HourTypeColumn extends Column {
    title = 'time-tracking.myTimesheetIndexPage.table.hourType';
    property = 'incomeType';
    type: CellDisplay = CellDisplay.string;

    constructor(private payrollIncomeTypes: PayrollIncomeType[]) {
        super();
    }

    getDisplayPropertyValue(timesheetEntry: TimesheetEntry): string | TranslatableKey {
        if (timesheetEntry.salaryId) {
            return timesheetEntry.salaryName
                ? timesheetEntry.salaryName
                : 'time-tracking.myTimesheetIndexPage.table.unknownSalaryName';
        }

        if (timesheetEntry.incomeTypeId) {
            const incomeType = this.payrollIncomeTypes.find((item) => item.id === timesheetEntry.incomeTypeId);
            if (incomeType?.displayName) {
                return incomeType.displayName;
            }
        }

        if (timesheetEntry.incomeType) {
            const incomeType = this.payrollIncomeTypes.find((item) => item.name === timesheetEntry.incomeType);
            if (incomeType?.displayName) {
                return incomeType.displayName;
            }
        }

        return timesheetEntry.incomeType ?? '-';
    }
}
