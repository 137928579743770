import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import {
    ProjectEmployeeAssignment,
    ProjectEmployeeAssignJSONApiRequest,
} from '../interfaces/project-employee-assignment.interface';
import { TimeTrackingResources } from '../time-tracking.resource';
import { Project } from '@models/time-tracking/project.model';

@Injectable()
export class ProjectEmployeeAssignmentService {
    constructor(private http: HttpClient) {}

    /**
     * Attach/detach many employees to many projects with a single API call.
     * Plays nice with app-common-bulk-select-employee-dialog UI component
     */
    async assign(attach?: ProjectEmployeeAssignment[], detach?: ProjectEmployeeAssignment[]): Promise<void> {
        // Create payload for assignment request.
        const payload: ProjectEmployeeAssignJSONApiRequest = {
            data: {
                type: 'timeTracking.projectAssignments',
                attributes: {
                    attach,
                    detach,
                },
            },
        };

        await this.http.post(TimeTrackingResources.AssignProjectEmployee, payload).toPromise();
    }

    /**
     * Helper to create an array of ProjectEmployeeAssignment objects
     * Params takes one project, many employees
     */
    createForProject(project: Project, employees: Employee[]): ProjectEmployeeAssignment[] {
        return employees.map((employee) => ({
            projectId: project.id,
            employeeId: employee.id,
        }));
    }

    /**
     * Helper to create an array of ProjectEmployeeAssignment objects
     * Params take one employee, many projects
     */
    createForEmployee(employee: Employee, projects: Project[]): ProjectEmployeeAssignment[] {
        return projects.map((project) => ({
            projectId: project.id,
            employeeId: employee.id,
        }));
    }
}
