import { Project } from '@app/models/time-tracking/project.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { LanguageService } from '@app/services';

export class Status extends BadgeColumn<Project> {
    title = 'time-tracking.indexProjectsPage.table.status';
    property = null;
    type: CellDisplay = CellDisplay.badge;

    constructor(protected languageService: LanguageService) {
        super();

        const options = [
            {
                label: this.languageService.translate('time-tracking.status.active'),
                value: 0,
            },
            {
                label: this.languageService.translate('time-tracking.status.archived'),
                value: 1,
            },
        ];

        this.filter = new CheckListFilter('isArchived', options, 'time-tracking.indexProjectsPage.table.status');
    }

    getBadgeDisplay(project: Project): BadgeDisplay {
        if (project.isArchived) {
            return {
                type: 'danger',
                label: this.languageService.translate('time-tracking.status.archived'),
            };
        }

        return {
            type: 'informative',
            label: this.languageService.translate('time-tracking.status.active'),
        };
    }
}
