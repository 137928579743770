import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { TimeTrackingResources } from '../time-tracking.resource';

@Injectable({ providedIn: 'root' })
export class ReportDownloadService {
    constructor(private http: HttpClient) {}

    async downloadFile(fileType: string, searchPayload: string): Promise<Blob> {
        const url = `${TimeTrackingResources.EmployeeReport}/${fileType}?${searchPayload}`;

        return await this.http.get(url, { responseType: 'blob' }).toPromise();
    }

    async downloadFileForEmployee(fileType: string, employee: Employee, searchPayload: string): Promise<Blob> {
        searchPayload = searchPayload.replace(/&?filters%5BemployeeId%5D=[0-9]+/, '');

        const url = `${TimeTrackingResources.EmployeeProfileReport}/${fileType}?${searchPayload}&filters[employeeId][0]=${employee.id}`;

        return await this.http.get(url, { responseType: 'blob' }).toPromise();
    }
}
