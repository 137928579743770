import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Department } from '@models/company/department.model';

export class DepartmentColumn extends Column {
    title = 'time-tracking.reportsIndexPage.table.department';
    property = 'employee.department.name';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    sortField = 'employeeDepartmentName';

    constructor(protected companyId: number) {
        super();

        const options = Department.param('company', this.companyId)
            .orderBy('name', 'ASC')
            .all()
            .then(([departments]) =>
                departments.map((department: Department) => ({
                    label: department.name,
                    value: department.id,
                }))
            );

        this.filter = new CheckListFilter('departmentId', options, 'time-tracking.reportsIndexPage.table.department');
    }
}
