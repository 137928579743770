import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { ButtonColumn } from '@app/modules/table/classes/button-column.class';
import { ButtonTypes } from '@app/components/platform/button/button.component';

export class ApproveButtonColumn extends ButtonColumn<TimesheetEntry> {
    title = ''; // we don't want a column title
    buttonText = 'time-tracking.approve';
    buttonType: ButtonTypes = 'link';
    event = 'approve';

    showIf(timesheetEntry: TimesheetEntry): boolean {
        return !timesheetEntry.isApproved;
    }
}
