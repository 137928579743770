import { Paginator, SortDirection } from '@app/classes';
import { EmployeeReport } from '@app/models/time-tracking/employee-report.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { DepartmentColumn, HoursColumn, NameColumn, PositionColumn, ProjectColumn } from './columns';

export class EmployeeReportsIndexTable extends Table {
    sortDirection = SortDirection.DESC;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const nameColumn = new NameColumn();

        const columns = [
            nameColumn,
            new PositionColumn(this.auth?.company?.id),
            new DepartmentColumn(this.auth.company?.id),
            new ProjectColumn(),
            new HoursColumn(),
        ];

        this.columns = columns;
        this.sortColumn = nameColumn;
    }

    setDataSource(): void {
        this.paginator = new Paginator(EmployeeReport.with(['project', 'employee']));
    }
}
