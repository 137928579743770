import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class NameColumn extends Column {
    title = 'time-tracking.settings.name';
    property = null;
    sortable = true;
    sortField = 'lastName';
    type: CellDisplay = CellDisplay.avatar;
}
