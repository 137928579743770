import { Job } from '@app/models/company/job.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class PositionColumn extends Column {
    title = 'time-tracking.reportsIndexPage.table.position';
    property = 'employee.job.title';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    sortField = 'employeeJobTitle';

    constructor(protected companyId: number) {
        super();

        const options = Job.param('company', this.companyId)
            .orderBy('title', 'ASC')
            .all()
            .then(([jobs]) =>
                jobs.map((job: Job) => ({
                    label: job.title,
                    value: job.id,
                }))
            );

        this.filter = new CheckListFilter('jobId', options, 'time-tracking.reportsIndexPage.table.position');
    }
}
