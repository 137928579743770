/**
 * minutesToHoursAndMinutes converts minutes into a value the user can understand
 *
 * Ex: 12 minutes => 0:12
 * Ex: 120 minutes => 2:00
 */
export function minutesToHoursAndMinutes(totalMinutes: number): string {
    const hours = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;
    return `${hours.toString().padStart(1, '0')}:${mins.toString().padStart(2, '0')}`;
}
