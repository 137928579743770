import { DateRangeFilter } from '@app/modules/table/classes/column-filters/date-range/date-range-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class DateColumn extends Column {
    title = 'time-tracking.timesheetsIndexPage.table.date';
    property = 'workedOn';
    type: CellDisplay = CellDisplay.date;
    sortable = true;

    constructor() {
        super();
        this.filter = new DateRangeFilter('workedOn', undefined, 'time-tracking.timesheetsIndexPage.table.date');
    }
}
