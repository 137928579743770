import { ModelMixin } from '@app/models/core/base-generic.model';
import { Employee } from '@models/employee/employee.model';
import { Project } from '@models/time-tracking/project.model';

// This model is not a resourceful endpoint and is only used with the time tracking reporting endpoint

export class EmployeeReport extends ModelMixin<EmployeeReport>() {
    protected static _type = 'timeTracking.reports.employees';
    protected static _resource = 'timeTracking/reports/employees';
    protected static _version = 'v2';

    get totalMinutes(): number {
        return this._attributes['totalMinutes'];
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get project(): Project | null {
        return this.hasOne(Project, 'project');
    }
}
