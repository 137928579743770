import { ProjectAssignment } from '@app/models/time-tracking/project-assignment.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { minutesToHoursAndMinutes } from '@app/modules/time-tracking/functions/minutes-to-hours-and-minutes';

export class HoursColumn extends Column {
    title = 'time-tracking.myProjectsPage.table.myApprovedHours';
    property = 'timesheetsSumMinutes';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(projectAssignment: ProjectAssignment): string {
        return minutesToHoursAndMinutes(projectAssignment.timesheetsApprovedSumMinutes);
    }
}
