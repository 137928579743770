import { Injectable } from '@angular/core';
import { Paginator, Platform } from '@app/classes';
import { Project } from '@app/models/time-tracking/project.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService, LanguageService } from '@app/services';
import { NameColumn, CreatedAtColumn, EmployeesAssigned, ApprovedHours, Status } from './columns';

const deleteOption: MenuColumnItem = {
    label: 'time-tracking.delete',
    event: 'delete',
};

const archiveOption: MenuColumnItem = {
    label: 'time-tracking.archive',
    event: 'archive',
};

const editOption: MenuColumnItem = {
    label: 'time-tracking.edit',
    event: 'edit',
};
const markAsActiveOption: MenuColumnItem = {
    label: 'time-tracking.markAsActive',
    event: 'markAsActive',
};

@Injectable()
export class ProjectsIndexTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        protected languageService: LanguageService
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const createdAtColumn = new CreatedAtColumn();

        const columns = [
            new NameColumn(),
            createdAtColumn,
            new EmployeesAssigned(),
            new ApprovedHours(),
            new Status(this.languageService),
        ];

        this.sortColumn = createdAtColumn;
        this.columns = columns;
    }

    getMenuColumnOptionsForRow(project: Project): MenuColumnItem[] {
        if (this.auth.can(Platform.timeTracking.permission.view)) {
            if (project.isArchived && project.hasTimesheetEntries) {
                return [markAsActiveOption];
            }

            if (project.isArchived) {
                return [markAsActiveOption, deleteOption];
            }

            return [editOption, archiveOption, deleteOption];
        }

        // in this case, the project won't be visible, but let's handle it anyway
        if (project.isArchived) {
            return [];
        }

        return [editOption];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Project.query());
    }
}
