import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class NameColumn extends Column {
    title = 'time-tracking.reportsIndexPage.table.name';
    property = 'employee';
    sortField = 'employeeLastName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
}
