import { Injectable } from '@angular/core';
import { Paginator, Platform } from '@app/classes';
import { Employee } from '@app/models/employee/employee.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { NameColumn, AssignedAt } from './columns';

const removeOption: MenuColumnItem = {
    label: 'time-tracking.remove',
    event: 'remove',
};

@Injectable()
export class EmployeesAssignedToTimeTracking extends Table {
    showMenuColumnOptions = true;
    constructor(protected auth: AuthService) {
        super();
        this.boot();
    }

    setColumns(): void {
        const columns = [new NameColumn(), new AssignedAt()];

        this.columns = columns;
    }

    getMenuColumnOptionsForRow(employee: Employee): MenuColumnItem[] {
        return [removeOption];
    }

    setDataSource(): void {
        // should never happen
        if (!this.auth.company) {
            return;
        }

        const queryBuilder = Employee.query()
            .param('company', this.auth.company.id)
            .where('isAssignedModule', Platform.modules.timeTracking)
            .with(['activeModuleAssignments.module', 'activeTimeTrackingProjectOwnerships', 'timeTrackingAttributes'])
            .limit(10);
        this.paginator = new Paginator(queryBuilder, { pageSize: 10 });
    }
}
