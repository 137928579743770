import { Project } from '@app/models/time-tracking/project.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { LimitToPipe } from '@app/pipes';

export class NameColumn extends Column {
    title = 'time-tracking.indexProjectsPage.table.name';
    property = 'name';
    sortField = 'name';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    limitToPipe = new LimitToPipe();

    getDisplayPropertyValue(project: Project): string {
        return this.limitToPipe.transform(project.name, 50);
    }
}
