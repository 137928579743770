import { Platform } from '@app/classes';
import { EmployeeModuleAssignment } from '@app/models/common/employee-module-assignment';
import { Employee } from '@app/models/employee/employee.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class AssignedAt extends Column {
    title = 'time-tracking.settings.assignedAt';
    sortField = 'activeModuleAssignmentsAssignedAt';
    sortable = true;
    type: CellDisplay = CellDisplay.date;

    getDisplayPropertyValue(employee: Employee): string | Date {
        const assignedAt = employee.activeModuleAssignments.find(
            (moduleAssignment: EmployeeModuleAssignment) =>
                moduleAssignment.module.name === Platform.modules.timeTracking
        )?.assignedAt;

        return assignedAt || '-';
    }
}
