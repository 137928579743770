import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { minutesToHoursAndMinutes } from '@time-tracking/functions/minutes-to-hours-and-minutes';
import { Project } from '@models/time-tracking/project.model';

export class ApprovedHours extends Column {
    title = 'time-tracking.indexProjectsPage.table.approvedHours';
    property = 'timesheetsApprovedSumMinutes';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(project: Project): string {
        return minutesToHoursAndMinutes(project.timesheetsApprovedSumMinutes);
    }
}
