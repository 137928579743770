import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { minutesToHoursAndMinutes } from '@app/modules/time-tracking/functions/minutes-to-hours-and-minutes';

export class HoursColumn extends Column {
    title = 'time-tracking.myTimesheetIndexPage.table.hours';
    property = 'minutes';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(timesheetEntry: TimesheetEntry): string {
        return minutesToHoursAndMinutes(timesheetEntry.minutes);
    }
}
