import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { FilterValue } from '@app/modules/table/types/filter-value.type';
import { ProjectAssignment } from '@models/time-tracking/project-assignment.model';

export class ProjectColumn extends Column {
    title = 'time-tracking.myTimesheetIndexPage.table.project';
    property = 'project';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    sortField = 'projectNameWithNull';

    constructor(employeeId: number) {
        super();
        this.setOptions(employeeId);
    }

    getDisplayPropertyValue(timesheetEntry: TimesheetEntry): string {
        return timesheetEntry.project ? timesheetEntry.project.name : '-';
    }

    private setOptions(employeeId: number): void {
        const options = ProjectAssignment.with('project')
            .where('employeeId', employeeId)
            .all()
            .then(([projectAssignments]) => {
                // Create array of options using all related projects
                const projectsOptions: { label: string; value: FilterValue }[] = projectAssignments
                    .map((projectAssignment: ProjectAssignment) => ({
                        label: projectAssignment.project.name,
                        value: projectAssignment.project.id,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label));

                projectsOptions.unshift({ label: '-', value: null });

                return projectsOptions;
            });

        this.filter = new CheckListFilter('projectId', options, 'time-tracking.timesheetsIndexPage.table.project');
    }
}
