import { EmployeeReport } from '@app/models/time-tracking/employee-report.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { minutesToHoursAndMinutes } from '@app/modules/time-tracking/functions/minutes-to-hours-and-minutes';

export class HoursColumn extends Column {
    title = 'time-tracking.reportsIndexPage.table.totalHours';
    property = 'totalMinutes';
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(employeeReport: EmployeeReport): string {
        return minutesToHoursAndMinutes(employeeReport.totalMinutes);
    }
}
