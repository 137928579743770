import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TimeTrackingResources } from '../time-tracking.resource';
import { Employee } from '@models/employee/employee.model';

@Injectable()
export class ProjectOwnershipService {
    constructor(private http: HttpClient) {}

    /**
     * All-in-one add/remove/update project owners with a single API call.
     * This should only ever be used with project store/update.
     */
    async batch(projectId: number, owners: Employee[]): Promise<void> {
        const payload = {
            data: {
                type: 'timeTracking.projectOwnerships',
                attributes: {
                    ownerships: this.createEntryData(owners),
                },
            },
        };

        const url = TimeTrackingResources.ProjectOwnerBatch.replace(':projectId', projectId.toString());
        await this.http.post(url, payload).toPromise();
    }

    private createEntryData(owners: Employee[]): { ownerId: number }[] {
        return owners.map((owner) => ({
            ownerId: owner.id,
        }));
    }
}
