import { Injectable } from '@angular/core';
import { Platform } from '@app/classes';
import { MenuItem, ShowWhenServices } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

const TimeTrackingMenu: MenuItem[] = [
    {
        key: 'time-tracking.adminMenu.timesheetApproval',
        link: ['/time-tracking', 'timesheets'],
        showWhen: ({ authService }: ShowWhenServices): boolean =>
            Boolean(authService.employee?.currentUserSupervisesTimeTracking),
    },
    {
        key: 'time-tracking.adminMenu.myTimesheet',
        link: ['/time-tracking', 'my-timesheet'],
        showWhen: ({ authService }: ShowWhenServices): boolean =>
            Boolean(authService.employee?.hasModule(Platform.modules.timeTracking)),
    },
    {
        key: 'time-tracking.adminMenu.projects',
        link: ['/time-tracking', 'projects'],
        showWhen: ({ authService }: ShowWhenServices): boolean => {
            if (!authService.employee?.hasModule(Platform.modules.timeTracking)) {
                return false;
            }
            return Boolean(
                authService.can(Platform.timeTracking.permission.view) ||
                    authService.employee?.activeTimeTrackingProjectOwnerships.length
            );
        },
    },
    {
        key: 'time-tracking.adminMenu.reports',
        link: ['/time-tracking', 'reports'],
        showWhen: ({ authService }: ShowWhenServices): boolean =>
            Boolean(
                authService.employee?.hasModule(Platform.modules.timeTracking) &&
                    authService.can(Platform.timeTracking.permission.view)
            ),
    },
    {
        key: 'time-tracking.adminMenu.settings',
        link: ['/time-tracking', 'settings'],
        showWhen: ({ authService }: ShowWhenServices): boolean =>
            authService.can(Platform.timeTracking.permission.view),
    },
    {
        key: 'time-tracking.employeeMenu.myProjects',
        link: ['/time-tracking', 'my-projects'],
        showWhen: ({ authService }: ShowWhenServices): boolean =>
            Boolean(
                authService.employee?.hasModule(Platform.modules.timeTracking) &&
                    authService.cant(Platform.timeTracking.permission.view)
            ),
    },
];

@Injectable()
export class TimeTrackingMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = TimeTrackingMenu;
    }
}
